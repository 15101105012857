import { MouseEvent, useEffect, useState } from 'react';

import { Button, Tooltip } from '@hero-design/react';

import {
  TRACK_ATS_SAVE_JOB,
  useMixpanelTracking,
} from '@shared/trackTools/mixpanel';

import useSaveJob from './hooks/useSaveJob';
import useUnsaveJob from './hooks/useUnsaveJob';

type SaveJobButtonProps = {
  isSaved: boolean | null;
  careerPageJobId: string;
  fetchSavedJobs?: () => void;
  onSave?: (jobId: string) => void;
  isFromDefaultOrg?: boolean;
};

const SaveJobButton = ({
  isSaved,
  careerPageJobId,
  fetchSavedJobs,
  onSave: externalOnSave,
  isFromDefaultOrg = false,
}: SaveJobButtonProps) => {
  const { track } = useMixpanelTracking();
  const [bookmarked, setBookmark] = useState(isSaved);

  const { fetcher: saveJob, loading: savingJob } = useSaveJob({
    onCompleted: () => setBookmark(true),
  });

  const { fetcher: unsaveJob, loading: unsavingJob } = useUnsaveJob({
    onCompleted: () => {
      setBookmark(false);

      if (fetchSavedJobs) {
        fetchSavedJobs();
      }
    },
  });

  useEffect(() => {
    setBookmark(isSaved);
  }, [isSaved]);

  const onSaveJob = (event: MouseEvent) => {
    event.stopPropagation();

    if (!bookmarked) {
      externalOnSave?.(careerPageJobId);
      saveJob({ jobId: careerPageJobId });
      track(TRACK_ATS_SAVE_JOB);
    }
  };

  const onUnsaveJob = async (event: MouseEvent) => {
    event.stopPropagation();
    if (bookmarked) {
      unsaveJob({ jobId: careerPageJobId });
    }
  };

  return (
    <Tooltip
      target={
        <>
          {bookmarked ? (
            <Button.Icon
              icon="bookmark"
              intent="warning"
              sx={{ alignItems: 'center' }}
              size="large"
              data-test-id="unsave-job-btn"
              onClick={onUnsaveJob}
              disabled={unsavingJob || savingJob}
            />
          ) : (
            <Button.Icon
              icon="bookmark-outlined"
              intent={isFromDefaultOrg ? 'text' : 'subdued-text'}
              sx={{
                alignItems: 'center',
              }}
              size="large"
              data-test-id="save-job-btn"
              onClick={onSaveJob}
              disabled={unsavingJob || savingJob}
            />
          )}
        </>
      }
      content={bookmarked ? 'Unsaved job' : 'Save job'}
    />
  );
};

export default SaveJobButton;
