import styled, { useTheme } from 'styled-components';

import { Box } from '@hero-design/react';

import Image from '@packages/eh-adapter/image';
import { mediaMaxQueries } from '@packages/hero-theme/utils';
import useWindowSize from '@shared/hooks/useWindowSize';

import UploadVideoIntroduction from './UploadVideoIntroduction';

const StyledWrapper = styled.div`
  overflow: hidden;
  margin-bottom: ${({ theme }) => theme.space.large}px;
  border-radius: ${({ theme }) => theme.radii.medium}px;
  background-color: ${({ theme }) => theme.colors.defaultLightBackground};
  display: flex;
  overflow: hidden;

  ${mediaMaxQueries.md} {
    height: auto;
    flex-direction: column;
  }
`;

const RecordVideoIntroSection = ({
  isVerticalLayout,
  onRecordButtonClicked,
  onUseVideo,
  onUploadSuccess,
  title,
  subtitle,
  IntroductionVideoBenefits,
}: {
  isVerticalLayout?: boolean;
  onRecordButtonClicked?: () => void;
  onUseVideo?: (blob: Blob) => void;
  onUploadSuccess?: () => void;
  title?: string;
  subtitle?: string | null;
  IntroductionVideoBenefits?: React.ComponentType;
}) => {
  const theme = useTheme();
  const { isSmallScreen } = useWindowSize();
  const canUseColumnLayout = isVerticalLayout || isSmallScreen;

  return (
    <StyledWrapper>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: canUseColumnLayout ? 0 : 'large',
          gap: canUseColumnLayout ? 0 : 'large',
          flexWrap: 'wrap',
          flexDirection: canUseColumnLayout ? 'column' : 'row',
        }}
      >
        <Box
          sx={{
            background: canUseColumnLayout ? theme.colors.primary : undefined,
            display: 'flex',
            flex: 1,
            borderTopLeftRadius: canUseColumnLayout
              ? theme.radii.medium
              : undefined,
            borderTopRightRadius: canUseColumnLayout
              ? theme.radii.medium
              : undefined,
          }}
        >
          <Image
            src={
              canUseColumnLayout
                ? '/assets/images/video-introduction-banner.png'
                : '/assets/images/video-intro-banner-desktop.png'
            }
            alt="Video Introduction Banner"
            data-test-id="video-introduction-banner"
            style={{
              borderRadius: canUseColumnLayout ? undefined : theme.radii.medium,
              width: '100%',
              objectFit: 'cover',
            }}
          />
        </Box>

        <Box
          sx={{
            flex: 1,
            p: canUseColumnLayout ? 'medium' : 0,
            m: canUseColumnLayout ? 0 : 'auto',
          }}
        >
          <UploadVideoIntroduction
            onRecordButtonClicked={onRecordButtonClicked}
            onUseVideo={onUseVideo}
            onUploadSuccess={onUploadSuccess}
            title={title}
            subtitle={subtitle}
            IntroductionVideoBenefits={IntroductionVideoBenefits}
          />
        </Box>
      </Box>
    </StyledWrapper>
  );
};

export default RecordVideoIntroSection;
